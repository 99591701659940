import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IoMdHome } from "react-icons/io";
import { PiMoneyBold } from "react-icons/pi";
import { FaRegFileAlt } from "react-icons/fa";
import { BsBarChartFill } from "react-icons/bs";
import { FaChartLine } from "react-icons/fa";
import { GrMapLocation } from "react-icons/gr";
import { FaHandshake } from "react-icons/fa";
import { IoCartOutline } from "react-icons/io5";
import { PiUsersThreeFill } from "react-icons/pi";
import { PiFarmBold } from "react-icons/pi";
import { BsFileEarmarkPlusFill } from "react-icons/bs";
import { RiBankFill } from "react-icons/ri";
import Cookies from "js-cookie";
import { getFazendas } from "../services/fazendaService";
import { FaLock, FaBox, FaUser, FaTruck } from "react-icons/fa";

export interface Fazenda {
  id: number;
  nome: string;
  idUser: string;
  data_cadastro: string;
  data_alteracao: string;
}

const MenuComponent: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [fazendas, setFazendas] = useState<Fazenda[]>([]);
  const [selectedFazenda, setSelectedFazenda] = useState<string>("");
  const [nomeUser, setNomeUser] = useState<string | undefined>(
    Cookies.get("NomeUserBrasao")
  );
  const [bloqueiaMenu, setBloqueiaMenu] = useState(false);
  const [isSubmenuVisible, setIsSubmenuVisible] = useState(false);
  const [isSubmenuCadastroVisible, setIsSubmenuCadastroVisible] =
    useState(false);

  const toggleSubmenu = () => {
    setIsSubmenuVisible(!isSubmenuVisible);
  };

  const toggleSubmenuCadastro = () => {
    setIsSubmenuCadastroVisible(!isSubmenuCadastroVisible);
  };

  useEffect(() => {
    const fetchFazendas = async () => {
      try {
        const fazendas = await getFazendas();
        const idUser = Cookies.get("TokenBrasao");
        const filteredFazendas = fazendas.filter(
          (fazenda: Fazenda) => fazenda.idUser == idUser
        );
        setFazendas(filteredFazendas);
        Cookies.set("FazendaLista", JSON.stringify(filteredFazendas));

        const fazendaIdFromCookie = Cookies.get("FazendaId");
        if (fazendaIdFromCookie) {
          setSelectedFazenda(fazendaIdFromCookie);
        }
      } catch (error) {
        console.error("Error fetching fazendas:", error);
      }
    };

    const tipoUserLogin = Cookies.get("TipoUserBrasao");
    if (tipoUserLogin === "GE") {
      setBloqueiaMenu(true);
    }

    fetchFazendas();
  }, []);

  useEffect(() => {
    const path = location.pathname;

    if (path === "/contas-bancarias") {
      setIsSubmenuVisible(true);
    } else {
      setIsSubmenuVisible(false);
    }

    if (
      path.startsWith("/fazenda/") ||
      path === "/fazendas" ||
      path === "/operadores" ||
      path === "/vendedores" ||
      path === "/clientes" ||
      path === "/categorias"
    ) {
      setIsSubmenuCadastroVisible(true);
    } else {
      setIsSubmenuCadastroVisible(false);
    }
  }, [location.pathname]);

  const isActive = (path: string) =>
    location.pathname === path ? "active" : "";

  const handleSelectChange = (event: React.ChangeEvent) => {
    const selectedId = (event.target as HTMLSelectElement).value;
    if (selectedId) {
      const selectedFazenda = fazendas.find(
        (fazenda) => fazenda.id === parseInt(selectedId)
      );
      if (selectedFazenda) {
        setSelectedFazenda(selectedId);
        Cookies.set("FazendaId", selectedId);
        Cookies.set("FazendaNome", selectedFazenda.nome);
      }
    }
  };

  return (
    <>
      <div className="sidebar sidebar-light sidebar-main sidebar-expand-lg bg-indigo">
        <div className="sidebar-content h-auto" style={{ maxHeight: "90px" }}>
          <ul className="nav nav-sidebar" data-nav-type="accordion">
            <li className="nav-item-header pb-0">
              <div className="line-height-xs mt-1 text-white">
                Olá: <b>{nomeUser}</b>
              </div>
              <div className="form-group mb-0">
                <select
                  className="form-control form-control-sm"
                  name="idFazenda"
                  onChange={handleSelectChange}
                  value={selectedFazenda}
                >
                  <option value="">Fazenda: selecione</option>
                  {fazendas.map((fazenda) => (
                    <option key={fazenda.id} value={fazenda.id}>
                      {fazenda.nome}
                    </option>
                  ))}
                </select>
              </div>
            </li>
          </ul>
        </div>
        <div className="sidebar-section">
          <ul className="nav nav-sidebar menuLateral" data-nav-type="accordion">
            <li className={`nav-item`}>
              <a
                onClick={() => navigate("/")}
                className={`nav-link pt-1 pb-1 ${isActive("/")}`}
              >
                <i className="fs-1-5em text-white">
                  <IoMdHome />
                </i>
                <span className="pt-03-em text-white">Início</span>
              </a>
            </li>

            {bloqueiaMenu === false && (
              <>
                <li className="nav-item">
                  <a
                    onClick={() => navigate("/relatorios")}
                    className={`nav-link pt-1 pb-1 ${isActive("/relatorios")}`}
                  >
                    <i className="fs-1-5em text-white">
                      <FaRegFileAlt />
                    </i>
                    <span className="pt-03-em text-white">Relatórios</span>
                  </a>
                </li>
                <li className={`nav-item`}>
                  <a
                    onClick={() => navigate("/fiscal")}
                    className={`nav-link pt-1 pb-1 ${isActive("/fiscal")}`}
                  >
                    <i className="fs-1-5em text-white">
                      <BsBarChartFill />
                    </i>
                    <span className="pt-03-em text-white">Fiscal</span>
                  </a>
                </li>
                <li className={`nav-item`}>
                  <a
                    onClick={() => navigate("/investimentos")}
                    className={`nav-link pt-1 pb-1 ${isActive("/investimentos")}`}
                  >
                    <i className="fs-1-5em text-white">
                      <FaChartLine />
                    </i>
                    <span className="pt-03-em text-white">Investimentos</span>
                  </a>
                </li>
                <li className={`nav-item`}>
                  <a
                    onClick={() => navigate("/areas")}
                    className={`nav-link pt-1 pb-1 ${isActive("/areas")}`}
                  >
                    <i className="fs-1-5em text-white">
                      <GrMapLocation />
                    </i>
                    <span className="pt-03-em text-white">Área</span>
                  </a>
                </li>
                <li className={`nav-item`}>
                  <a
                    onClick={() => navigate("/vendas")}
                    className={`nav-link pt-1 pb-1 ${isActive("/vendas")}`}
                  >
                    <i className="fs-1-5em text-white">
                      <FaHandshake />
                    </i>
                    <span className="pt-03-em text-white">Vendas</span>
                  </a>
                </li>
                <li className={`nav-item`}>
                  <a
                    onClick={() => navigate("/compras")}
                    className={`nav-link pt-1 pb-1 ${isActive("/compras")}`}
                  >
                    <i className="fs-1-5em text-white">
                      <IoCartOutline />
                    </i>
                    <span className="pt-03-em text-white">Compras</span>
                  </a>
                </li>
                <li
                  className="nav-item nav-item-submenu"
                  onClick={toggleSubmenu}
                >
                  <a className={`nav-link pt-1 pb-1`}>
                    <i className="fs-1-5em text-white">
                      <PiMoneyBold />
                    </i>
                    <span className="pt-03-em text-white">Financeiro</span>
                  </a>
                  <ul
                    className="nav nav-group-sub"
                    data-submenu-title="Financeiro"
                    style={{ display: isSubmenuVisible ? "block" : "none" }}
                  >
                    <li className="nav-item py-1">
                      <a
                        onClick={() => navigate("/contas-bancarias")}
                        className="nav-link pl-4 py-1"
                      >
                        <i className="fs-1-5em mr-2 text-white">
                          <RiBankFill />
                        </i>
                        <span className="pt-03-em text-white">
                          Contas bancárias
                        </span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  className="nav-item nav-item-submenu"
                  onClick={toggleSubmenuCadastro}
                >
                  <a className={`nav-link pt-1 pb-1`}>
                    <i className="fs-1-5em text-white">
                      <BsFileEarmarkPlusFill />
                    </i>
                    <span className="pt-03-em text-white">Cadastro</span>
                  </a>
                  <ul
                    className="nav nav-group-sub"
                    data-submenu-title="Cadastro"
                    style={{
                      display: isSubmenuCadastroVisible ? "block" : "none",
                    }}
                  >
                    <li className="nav-item py-1">
                      <a
                        onClick={() => navigate("/fazendas")}
                        className="nav-link pl-4 py-1"
                      >
                        <i className="fs-1-5em mr-2 text-white">
                          <PiFarmBold />
                        </i>
                        <span className="pt-03-em text-white">Fazendas</span>
                      </a>
                    </li>
                    <li className="nav-item py-1">
                      <a
                        onClick={() => navigate("/clientes")}
                        className="nav-link pl-4 py-1"
                      >
                        <i className="fs-1-5em mr-2 text-white">
                          <FaUser />
                        </i>
                        <span className="pt-03-em text-white">
                          Compradores / Fornecedores / Transportadoras
                        </span>
                      </a>
                    </li>
                    <li className="nav-item py-1">
                      <a
                        onClick={() => navigate("/categorias")}
                        className="nav-link pl-4 py-1"
                      >
                        <i className="fs-1-5em mr-2 text-white">
                          <FaBox />
                        </i>
                        <span className="pt-03-em text-white">
                          Categorias / Produtos
                        </span>
                      </a>
                    </li>
                    <li className="nav-item py-1">
                      <a
                        onClick={() => navigate("/operadores")}
                        className="nav-link pl-4 py-1"
                      >
                        <i className="fs-1-5em mr-2 text-white">
                          <FaUser />
                        </i>
                        <span className="pt-03-em text-white">Operadores</span>
                      </a>
                    </li>
                    <li className="nav-item py-1">
                      <a
                        onClick={() => navigate("/vendedores")}
                        className="nav-link pl-4 py-1"
                      >
                        <i className="fs-1-5em mr-2 text-white">
                          <FaUser />
                        </i>
                        <span className="pt-03-em text-white">
                          Fornecedores / Produtos
                        </span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className={`nav-item`}>
                  <a
                    onClick={() => navigate("/usuarios")}
                    className={`nav-link pt-1 pb-1 ${isActive("/usuarios")}`}
                  >
                    <i className="fs-1-5em text-white">
                      <PiUsersThreeFill />
                    </i>
                    <span className="pt-03-em text-white">Usuários</span>
                  </a>
                </li>
                <li className={`nav-item`}>
                  <a
                    onClick={() => navigate("/alterar-senha")}
                    className={`nav-link pt-1 pb-1 ${isActive("/alterar-senha")}`}
                  >
                    <i className="fs-1-5em text-white">
                      <FaLock />
                    </i>
                    <span className="pt-03-em text-white">Alterar senha</span>
                  </a>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default MenuComponent;
